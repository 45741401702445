@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Open Sans", sans-serif;
  background-color: #F6FDFE;
}

.glass-nav {
  background-color: rgba(255, 255, 255, 0.01); 
  backdrop-filter: blur(10px); 
}

.about-bg {
  background-image: url(../src/Assests/scuba-diving-79606_640.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-bg::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(4, 33, 39, 0.7);
  border-radius: 10px;
}

.vertical-timeline-element p {
  font-size: 20px;
}

@keyframes slideIn {
  from {
    transform: translateX(-20%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-in {
  animation: slideIn 1s ease-out forwards;
}

.grayscale {
  filter: grayscale(100%);
  transition: filter 0.5s ease-in-out;
}

.grayscale.show-color {
  filter: grayscale(0%);
}

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background: skyblue;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
/* Service Slider Arrows */
.slick-arrow.service-next,
.slick-arrow.service-prev {
  background-color: #01365e;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  z-index: 10;
  position: absolute;
  top: -60px;
}

.slick-arrow.service-next {
  right: 1%;
}

.slick-arrow.service-prev {
  left: 90%;
}

@media (max-width: 767px) {
  .slick-arrow.service-next {
    top: 55%;
    right: -20px;
   
  }
  .slick-arrow.service-prev {
    top: 55%;
    left: -20px;
   
  }
}

.slick-arrow.service-next:hover,
.slick-arrow.service-prev:hover {
  background-color: #0469b7;
  opacity: 1;
}

.service-next::before, 
.service-prev::before {
  display: none;
}

.arrows {
  height: 25px;
  width: 25px;
}

/* Project Slider Arrows */
.slick-arrow.project-next,
.slick-arrow.project-prev {
  background-color: #01365e; 
  height: 50px; 
  width: 50px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 1;
  z-index: 10;
  position: absolute;
}

.slick-arrow.project-next {
  right: -10;
}

.slick-arrow.project-prev {
  left: -10;
}

@media (max-width: 767px) {
  .slick-arrow.project-next,
  .slick-arrow.project-prev {
    height: 30px;
    width: 30px;
  }
}
@media (max-width: 500px) {
  .slick-arrow.project-next,
  .slick-arrow.project-prev {
    height: 30px;
    width: 30px;
  }
  .slick-arrow.project-next {
    right: 20px;
  }
  
  .slick-arrow.project-prev {
    left: 20px;
  }
}

.slick-arrow.project-next:hover,
.slick-arrow.project-prev:hover {
  background-color: #0469b7;
  opacity: 1;
}

.project-next::before, 
.project-prev::before {
  display: none;
}

.project-arrows {
  height: 25px;
  width: 25px;
}

.vertical-timeline-element-content {
  box-shadow: none !important;
  border: none !important;
}
.projslides .slick-arrow.slick-next,
.projslides .slick-arrow.slick-prev{
background-color: #01365e;
height: 50px;
width: 50px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 1;
z-index: 10;
position: absolute;
top: -60px;
}